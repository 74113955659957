<template>
  <CCard class="newsletter-subscriber-import">
    <CCardHeader>
      <slot name="header">
        <b>Newsletter-Abonnenten importieren</b>
      </slot>
    </CCardHeader>
    <CCardBody>
      <CCol md="12" v-if="!showTable" class="pl-0 pr-2">
        <vue-csv-import
          v-model="csv"
          :map-fields="mapFields"
        >
          <template slot="hasHeaders" slot-scope="{headers, toggle}">
            <label style="margin-left: -20px;">
              <input type="checkbox" id="hasHeaders" :value="headers" @change="toggle">
              Die Datei hat eine Kopfzeile
            </label>
          </template>

          <template slot="error">
            Der Dateityp ist ungültig
          </template>

          <template slot="thead">
            <CAlert color="warning">
              Zielgruppen müssen kommasepariert in der Datei hinterlegt sein. Beispiel: Wertermittlung, Ratgeber, Webinar
            </CAlert>
            <tr>
              <th>Spaltenbeschriftungen den Abonnenteninformationen zuordnen</th>
              <th>Spalten in Ihrer Importdatei</th>
            </tr>
          </template>

          <template slot="next" slot-scope="{load}">
            <CButton color="success" class="mt-5" @click.prevent="load">Datei hochladen  </CButton>
          </template>
        </vue-csv-import>
      </CCol>

      <CCol md="12" v-if="showTable">
        <CDataTable
          :items="csv"
          :fields="getTableFields()"
          hover
          striped
          bordered
          fixed
          column-filter
          table-filter
          items-per-page-select
          sorter
          pagination
          :loading="loading"
        >
          <template #show_details="{item, index}">
            <td class="py-2">
              <CButton
                color="primary"
                variant="outline"
                square
                size="sm"
                @click.prevent="showEditModal(index, item)"
              >
                Editieren
              </CButton>
            </td>
          </template>
          <template #delete_item="{item, index}">
            <td class="py-2">
              <CButton
                color="danger"
                variant="outline"
                square
                size="sm"
                @click.prevent="deleteRecord(index)"
              >
                Löschen
              </CButton>
            </td>
          </template>
        </CDataTable>
      </CCol>

      <CCol md="12" v-if="visibleActionButton && !showTable" class="text-center">
        <CButton color="primary" @click.prevent="showTable = true">
          Weiter
        </CButton>
      </CCol>

      <CCol md="12" v-if="visibleActionButton && showTable" class="text-center">
        <CButton color="primary" @click.prevent="back()">
          Zurück
        </CButton>
        &nbsp;&nbsp;&nbsp;
        <CButton color="primary" @click.prevent="submit()">
          Import abschließen
        </CButton>
      </CCol>

      <CCol md="12">
        <CForm @submit.prevent="update">
          <CModal
            title="Abonnent hinzufügen"
            size="sm"
            :show.sync="visibleModal"
            :closeOnBackdrop="false"
          >
            <CRow>
              <CCol sm="12">
                <label>E-Mail Adresse</label>
                <CInput
                    type="email"
                    required
                    v-model="selectedItem && selectedItem['E-Mail']"
                />
              </CCol>

              <CCol sm="12">
                <label for="firstName">Vorname</label>
                <CInput
                  id="firstName"
                  required="true"
                  v-model="selectedItem && selectedItem['Vorname']"
                />
              </CCol>

              <CCol sm="12">
                <label for="lastName">Nachname</label>
                <CInput
                    id="lastName"
                    required="true"
                    v-model="selectedItem && selectedItem['Nachname']"
                />
              </CCol>

              <CCol sm="12">
                <label for="groups">Gruppen (Komma-seperiert)</label>
                <CInput
                    id="groups"
                    required="true"
                    v-model="selectedItem && selectedItem['Gruppen']"
                />
              </CCol>
            </CRow>

            <div slot="footer">
              <CButton
                class="mr-3"
                color="dark"
                variant="outline"
                @click="visibleModal = false"
              >
                Abbrechen
              </CButton>
              <CButton
                color="success"
                type="submit"
              >
                Speichern
              </CButton>
            </div>
          </CModal>
        </CForm>
      </CCol>
    </CCardBody>
  </CCard>
</template>

<script>
import axios from 'axios'
import _ from 'lodash'
import {VueCsvImport} from 'vue-csv-import'

export default {
  name: 'NewsletterSubscriberImport',
  components: {
    VueCsvImport,
  },
  mounted() {
    this.customizeFileInput()
  },
  data() {
    return {
      mapFields: ['E-Mail', 'Vorname', 'Nachname', 'Gruppen'],
      csv: null,
      tableData: [],
      showTable: false,
      visibleActionButton: false,
      loading: false,
      visibleModal: false,
      selectedIndex: null,
      selectedItem: null,
    }
  },
  watch: {
    csv(val) {
      if (val) {
        this.visibleActionButton = true
      }
    }
  },
  methods: {
    customizeFileInput() {
      document.querySelectorAll('.newsletter-subscriber-import .csv-import-file')[0].style.display = 'none'

      const divWrapper = document.createElement('div')
      divWrapper.setAttribute('class', 'row')

      const divOffset = document.createElement('div')
      divOffset.setAttribute('class', 'col-sm-3 mb-3')
      divWrapper.appendChild(divOffset)

      const div = document.createElement('div')
      div.setAttribute('class', 'form-group col-sm-9 mb-3')
      const label = document.createElement('label')
      label.setAttribute('for', 'csv-import-file')
      label.setAttribute('class', 'custom-file-label')
      label.style.display = 'block'
      label.innerHTML = 'Datei auswählen...'
      div.appendChild(label)

      divWrapper.appendChild(div)

      const csvInput = document.querySelectorAll('.newsletter-subscriber-import .form-control-file')[0]
      csvInput.addEventListener('change', function (e) {
        label.innerHTML = e.target.files[0].name
      })

      div.addEventListener("click", function () {
        csvInput.click()
      })
      const partOne = document.querySelectorAll('.newsletter-subscriber-import .vue-csv-uploader-part-one')[0]
      partOne.insertBefore(divWrapper, partOne.children[2])
    },
    getTableFields() {
      return [
        {
          key: 'E-Mail',
          label: 'E-Mail',
        },
        {
          key: 'Vorname',
          label: 'Vorname',
        },
        {
          key: 'Nachname',
          label: 'Nachname',
        },
        {
          key: 'Gruppen',
          label: 'Gruppen',
        },
        {
          key: 'show_details',
          label: '',
          _style: 'width:1%',
          sorter: false,
          filter: false
        },
        {
          key: 'delete_item',
          label: '',
          _style: 'width:1%',
          sorter: false,
          filter: false
        }
      ]
    },
    back() {
      this.showTable = false
      setTimeout(() => {
        this.customizeFileInput()
      }, 100)
    },
    async submit() {
      this.loading = true
      const subscribers = this.csv
      if (subscribers && subscribers.length > 0) {
        try {
          this.loading = true
          const groupsReq = await axios.get('/newsletter/group')
          const groups = groupsReq.data.reduce((acc,curr) => (acc[curr.name.toLowerCase()]=curr,acc), {})
          console.log(groups)
          const formattedSubscribers = this.formatSubscribersData(subscribers, groups)
          this.loading = false
          await this.addSubscribers(formattedSubscribers)
        } catch (err) {
          console.log(err)
          alert('Fehler beim importieren der Abonennten')
        } finally {
          this.loading = false
        }
      } else if (!this.csv || (this.csv && this.csv.length === 0)) {
        alert('Nichts hinzuzufügen')
      } else {
        alert('Bereits importierte Kontakte')
      }
    },
    formatSubscribersData(subscribers, groupsAlreadyExists) {
      let formattedSubscribers = []
      subscribers && subscribers.forEach(subscriber => {
        const entity = {
          email: subscriber['E-Mail'],
          firstName: subscriber['Vorname'],
          lastName: subscriber['Nachname'],
          groups: subscriber['Gruppen'],
        }
        entity.groups = entity.groups.split(',').map(groupString => {
          let matchedGroup = groupsAlreadyExists[groupString.toLowerCase()]
          return matchedGroup ? matchedGroup.id : ''
        })
        entity.groups = entity.groups.filter(group => group !== '')
        entity.groups = _.uniq(entity.groups)
        formattedSubscribers.push(entity)
      })

      return formattedSubscribers
    },
    async addSubscribers(subscribers) {
      try {
        this.loading = true
        await axios.post('/newsletter/subscriber/multiple', subscribers)
        this.$toaster.makeToast("Erfolgreich!", "Erfolgreich importiert")
        await this.$router.push({name: 'NewsletterSubscribers'})
      } catch (e) {
        console.log(e)
        this.$toaster.makeToast("Fehler!", "Fehler beim Import. Versuchen Sie es zu einem späteren Zeitpunkt wieder.")
      } finally {
        this.loading = false
      }
    },
    showEditModal(index, item) {
      this.selectedIndex = index
      this.selectedItem = item
      this.visibleModal = true
    },
    update() {
      if (this.selectedIndex !== null) {
        this.csv && this.csv.splice(this.selectedIndex, 1, this.selectedItem)
        this.visibleModal = false
      }
    },
    deleteRecord(index) {
      this.csv && this.csv.splice(index, 1)
    }
  }
}
</script>

<style>
  .cursor-pointer {
    cursor: pointer;
  }
</style>
