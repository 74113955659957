<template>
  <div>
    <CCard>
      <CCardHeader>
        <slot name="header">
          <b>Umzug von WordPress</b>
        </slot>
      </CCardHeader>
      <CCardBody>
        <CInputFile
          ref="migrationInputFile"
          label="Migrieren"
          :placeholder="placeholder"
          description="Exportieren Sie die Inhalte Ihres bisherigen Leadgenerators in Wordpress, dies können Sie im WordPress Administrationsbereich unter Leadgenerator > Import/Export machen. Laden Sie danach die prt-settings.json Datei hier hoch. "
          accept="application/json"
          @change="onChange"
          horizontal
          custom
        />
        <CButton color="success" @click="startMigration">Migration starten</CButton>
      </CCardBody>
    </CCard>

    <contact-import/>
    <NewsletterSubscriberImport/>
  </div>
</template>

<script>
  import axios from 'axios'
  import ContactImport from "./ContactImport";
  import NewsletterSubscriberImport from "@/views/base/NewsletterSubscriberImport";

  export default {
    name: 'ImportExport',
    data() {
      return {
        placeholder: 'Datei auswählen...',
        file: null,
      }
    },
    components: {
      NewsletterSubscriberImport,
      ContactImport
    },
    methods: {
      startMigration() {
        const reader = new FileReader();
        reader.onload = (event) => {
          try {
            let jsonObj = JSON.parse(event.target.result)
            axios.post('/wp-prt-migration', jsonObj)
              .then(() => {
                this.$toaster.makeToast('Migration abgeschlossen!', 'Die Migration von der WordPress PRT Instanz war erfolgreich.')
                this.placeholder = 'Migration erfolgreich!'
                this.file = null
              })
              .catch(err => {
                this.$toaster.makeToast('Fehler!', 'Während der Migration entstanden Fehler. Bitte versuchen Sie es später noch einmal.')
                console.error(err)
              })
          } catch (e) {
            this.$toaster.makeToast('Fehler!', 'Die angegebene Datei ist keine valide JSON Datei.')
          }
        }
        if (this.file) {
          reader.readAsText(this.file)
        } else {
          this.$toaster.makeToast('Fehler!', 'Bitte eine Datei auswählen.')
        }
      },
      onChange($event) {
        this.placeholder = $event[0].name
        this.file = $event[0]
      }
    }
  }
</script>

<style scoped>

</style>
